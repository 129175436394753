import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./VendorRegistration.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SIGNUP_VENDOR_URL } from "../apiExports";

export default function VendorRegistration() {
  const [data, setData] = useState({
    emails: [],
    srnumber: "",
    enrolled_to: [],
    vendor: "",
    ccEmails: [],
  });

  const [vendorEmailInputs, setVendorEmailInputs] = useState([0]);
  const [ccEmailInputs, setCCEmailInputs] = useState([0]);
  const [selected, setSelected] = useState(["critical_parameters"]);
  const [lastVendorEmailInputValue, setLastVendorEmailInputValue] =
    useState("");
  const [lastCCEmailInputValue, setLastCCEmailInputValue] = useState("");
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);

  useEffect(() => {
    console.log("Yhi hai");
  }, []);

  const options = [
    { label: "Critical Parameters", value: "critical_parameters" },
  ];

  const handleRemoveVendorEmailInput = (indexToRemove) => {
    setVendorEmailInputs((prevInputs) =>
      prevInputs.filter((_, index) => index !== indexToRemove)
    );

    console.log("Vendor Email Removal");
    setData((prevData) => {
      const newEmails = prevData.emails.filter(
        (_, index) => index !== indexToRemove
      );
      return {
        ...prevData,
        emails: newEmails,
      };
    });
  };

  const handleRemoveCCEmailInput = (indexToRemove) => {
    setCCEmailInputs((prevInputs) =>
      prevInputs.filter((_, index) => index !== indexToRemove)
    );

    console.log("CC Email Removal");
    setData((prevData) => {
      const newCCEmails = prevData.ccEmails.filter(
        (_, index) => index !== indexToRemove
      );
      return {
        ...prevData,
        ccEmails: newCCEmails,
      };
    });
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelected((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const handleChange = (e, index, type) => {
    const { value } = e.target;

    if (type === "vendor") {
      const emailArray = [...data.emails];
      emailArray[index] = value.trim().toLowerCase();
      setData((prev) => ({
        ...prev,
        emails: emailArray,
      }));

      if (index === vendorEmailInputs.length - 1) {
        setLastVendorEmailInputValue(value.trim().toLowerCase());
      }
    } else if (type === "cc") {
      const ccEmailArray = [...data.ccEmails];
      ccEmailArray[index] = value.trim().toLowerCase();
      setData((prev) => ({
        ...prev,
        ccEmails: ccEmailArray,
      }));

      if (index === ccEmailInputs.length - 1) {
        setLastCCEmailInputValue(value.trim().toLowerCase());
      }
    }
  };

  const handleAddEmailInput = (type) => {
    if (type === "vendor") {
      if (
        vendorEmailInputs.length < 10 &&
        lastVendorEmailInputValue.trim() !== ""
      ) {
        const timestamp = new Date().getTime();
        setVendorEmailInputs((prev) => [...prev, timestamp]);
        setLastVendorEmailInputValue("");
      }
    } else if (type === "cc") {
      if (
        vendorEmailInputs.length < 10 &&
        lastCCEmailInputValue.trim() !== ""
      ) {
        const timestamp = new Date().getTime();
        setCCEmailInputs((prev) => [...prev, timestamp]);
        setLastCCEmailInputValue("");
      }
    }
  };

  const isValidEmailArray = (emails) => {
    return emails.every((email) => isValidEmail(email));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!data.vendor || selected.length === 0) {
      toast.error("Dashboard(s) and Vendor Code are Mandatory Fields.");
      return;
    }

    if (data.emails.every((email) => !email.trim())) {
      toast.error("Enter at least 1 email!");
      return;
    }

    if (!isValidEmailArray(data.emails)) {
      toast.error("Invalid Input, enter valid Email(s)");
      return;
    }

    if (!disclaimerChecked) {
      toast.error("Please agree to the disclaimer before submitting.");
      return;
    }

    const allEmails = [...data.emails, ...data.ccEmails];

    const payloadArray = [];

    allEmails.forEach((email) => {
      selected.forEach((selectedValue) => {
        payloadArray.push({
          emails: [email],
          srnumber: data.srnumber,
          enrolled_to: selectedValue,
          vendor: data.vendor,
          status: "Active",
        });
      });
    });

    const promises = payloadArray.map((payload) => {
      return axios({
        method: "POST",
        url: SIGNUP_VENDOR_URL,
        data: payload,
      });
    });

    Promise.all(promises)
      .then((responses) => {
        console.log("done");
        toast.success("Registration Successful!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = () => {
    setDisclaimerChecked(!disclaimerChecked);
  };

  return (
    <>
      <div
        className="regcontainer"
        style={{
          marginBottom: "20px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <h3
          style={{
            fontFamily: "roboto",
            color: "Black",
            fontWeight: "normal",
            marginTop: "10px",
          }}
        >
          {" "}
          Vendor Registration{" "}
        </h3>
        <div className={styles.emaildashb}>
          <div className={styles.emailflex}>
            <div className={styles.enteremailtitle}>
              <label className={styles.label2} title="Mandatory Field">
                Enter Vendor Email<span className={styles.astspan}>*</span>
              </label>
              <button
                onClick={() => handleAddEmailInput("vendor")}
                className={styles.addemlbttn}
                style={{
                  marginLeft: "20px",
                  // margin: "15px",
                  backgroundColor: "#171C8F",
                  borderColor: "white",
                  borderRadius: "0.5rem",
                  color: "white",
                  // border: "black",
                  justifyContent: "flex-end",
                }}
                disabled={lastVendorEmailInputValue.trim() === ""}
              >
                + Add More Emails
              </button>
            </div>
            <div className={styles.emailGrid}>
              {vendorEmailInputs.map((_, index) => (
                <div key={index} className={styles.emailGriditem}>
                  <input
                    name="vendorEmail"
                    placeholder="Enter Email"
                    style={{ width: "250px" }}
                    value={data.emails[index] || ""}
                    onChange={(e) => handleChange(e, index, "vendor")}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginRight: "10px",
                    }}
                    onClick={() => handleRemoveVendorEmailInput(index)}
                  >
                    &#10006;
                  </span>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "5px", fontStyle: "italic" }}>
              <p>
                Note: Only 10 email id's can be added at a time for a single
                Vendor
              </p>
            </div>
          </div>
          <div className={styles.scndflex}>
            <label className={styles.label2} title="Mandatory Field">
              Select Email Alerts for: <span className={styles.astspan}>*</span>{" "}
            </label>
            <div className={styles.input_dash_wrapper}>
              {options.map((option) => (
                <div key={option.value}>
                  <input
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    type="checkbox"
                    value={option.value}
                    checked={selected.includes(option.value)}
                    onChange={handleSelect}
                  />
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.srnoappname} style={{ marginTop: "20px" }}>
          <div className={styles.emailflex}>
            <div className={styles.enteremailtitle}>
              <label className={styles.label2}>Enter CC Email</label>
              <button
                onClick={() => handleAddEmailInput("cc")}
                className={styles.addemlbttn}
                style={{
                  marginLeft: "20px",
                  // margin: "15px",
                  backgroundColor: "#171C8F",
                  borderColor: "white",
                  borderRadius: "0.5rem",
                  color: "white",
                  // border: "black",
                  justifyContent: "flex-end",
                }}
                disabled={lastCCEmailInputValue.trim() === ""}
              >
                + Add More CC Emails
              </button>
            </div>
            <div className={styles.emailGrid}>
              {ccEmailInputs.map((_, index) => (
                <div key={index} className={styles.emailGriditem}>
                  <input
                    name="ccEmail"
                    placeholder="Enter Email"
                    style={{ width: "250px" }}
                    value={data.ccEmails[index] || ""}
                    onChange={(e) => handleChange(e, index, "cc")}
                  />
                  <span
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginRight: "10px",
                    }}
                    onClick={() => handleRemoveCCEmailInput(index)}
                  >
                    &#10006;
                  </span>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "5px", fontStyle: "italic" }}>
              <p>Note: Adding CC email id's is an option, not a Necessity</p>
            </div>
          </div>
          <div className={styles.inputflex}>
            <label title="Mandatory Field" className={styles.vendorcodelabel}>
              Enter Vendor Code <span className={styles.astspan}>*</span>{" "}
            </label>
            <input
              style={{
                width: "200px",
                borderRadius: "5px",
                border: "1px solid black",
              }}
              name="vendor"
              value={data.vendor}
              onChange={(e) =>
                setData((prev) => ({ ...prev, vendor: e.target.value }))
              }
            />
          </div>
        </div>
        <div className={styles.subbttn}>
          <div
            style={{
              fontStyle: "italic",
              color: "gray",
              marginTop: "5px",
              width: "50%",
            }}
          >
            <p
              style={{
                wordWrap: "break-word",
                marginLeft: "5px",
                fontWeight: "400",
              }}
            >
              <input
                type="checkbox"
                id="disclaimerCheckbox"
                checked={disclaimerChecked}
                onChange={handleCheckboxChange}
                style={{ marginRight: "10px" }}
              />
              By checking this discalimer, I hereby declare that what is stated
              above is correct. I approve for daily alerts to be received by the
              added email id's. I also hereby confirm that I shall submit
              necessary proof of approval of Service request raised internally.
              Any discrepency arising out of a wrong declaration will be the
              responsibility of the individual concerned.
            </p>
            {/* <div className={styles.checkboxDisclaimer}>
              <input
                type="checkbox"
                id="disclaimerCheckbox"
                checked={disclaimerChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="disclaimerCheckbox" style={{ marginLeft: "5px" }}>
                I agree to the disclaimer
              </label>
            </div> */}
          </div>
          <div style={{ width: "50%" }} className={styles.finalbttn}>
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              style={{ backgroundColor: "#171C8F" }}
              // disabled={!disclaimerChecked}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
