import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Registration.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_DASHBOARD_URL, SIGNUP_URL } from "../apiExports";

export default function Registration() {
  const [data, setData] = useState({
    emails: [],
    srnumber: "",
    enrolled_to: [],
  });

  const [emailInputs, setEmailInputs] = useState([0]);

  useEffect(() => {
    fetchData();
  }, []);

  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [lastEmailInputValue, setLastEmailInputValue] = useState("");
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(GET_DASHBOARD_URL);
      console.log(response);
      setDashboardOptions(response.data || []);
    } catch (error) {
      console.error("Error fetching dashboard options:", error);
    }
  };

  const handleRemoveEmailInput = (indexToRemove) => {
    setEmailInputs((prevInputs) =>
      prevInputs.filter((_, index) => index !== indexToRemove)
    );

    console.log("Removal");
    setData((prevData) => {
      const newEmails = prevData.emails.filter(
        (_, index) => index !== indexToRemove
      );
      return {
        ...prevData,
        emails: newEmails,
      };
    });
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelected((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "emails") {
      const updatedEmails = [...data.emails];
      updatedEmails[index] = value.trim().toLowerCase();
      setData((prev) => ({
        ...prev,
        emails: updatedEmails,
      }));

      console.log(data.emails);

      if (index === emailInputs.length - 1) {
        setLastEmailInputValue(value.trim().toLowerCase());
      }

      console.log(data.emails);
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));

      console.log(data.emails);
    }
  };

  const handleAddEmailInput = () => {
    if (emailInputs.length < 10 && lastEmailInputValue.trim() !== "") {
      const timestamp = new Date().getTime();
      setEmailInputs((prev) => [...prev, timestamp]);
      setLastEmailInputValue("");
      console.log(data.emails);
    }
  };

  const isValidEmailArray = (emails) => {
    return emails.every((email) => isValidEmail(email));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@maruti\.co\.in$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (selected.length === 0) {
      toast.error("Selecting at least 1 Dashboard is Mandatory.");
      return;
    }

    const nonEmptyEmails = data.emails.filter((email) => email.trim() !== "");

    if (nonEmptyEmails.length === 0) {
      toast.error("Enter at least 1 email!");
      return;
    }

    if (!isValidEmailArray(nonEmptyEmails)) {
      toast.error(
        "Invalid email format. Please use emails ending with @maruti.co.in"
      );
      return;
    }

    if (!disclaimerChecked) {
      toast.error("Please agree to the disclaimer before submitting.");
      return;
    }

    const payloadArray = [];

    emailInputs.forEach((index) => {
      selected.forEach((selectedValue) => {
        const email = nonEmptyEmails[index];
        if (email) {
          payloadArray.push({
            emails: [email],
            srnumber: data.srnumber,
            enrolled_to: selectedValue,
            approved_by: "msil_Admin",
            status: "Active",
          });
        }
      });
    });

    const promises = payloadArray.map((payload) => {
      return axios({
        method: "POST",
        url: SIGNUP_URL,
        data: payload,
      });
    });

    Promise.all(promises)
      .then((responses) => {
        console.log("done");
        toast.success("Registration Successful!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = () => {
    setDisclaimerChecked(!disclaimerChecked);
  };

  return (
    <>
      <div
        className="regcontainer"
        style={{
          marginBottom: "20px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <h3
          style={{
            fontFamily: "roboto",
            color: "Black",
            fontWeight: "normal",
            marginTop: "10px",
          }}
        >
          {" "}
          Internal MSIL User Registration{" "}
        </h3>
        <div className={styles.emaildashb}>
          <div className={styles.emailflex}>
            <div className={styles.enteremailtitle}>
              <label className={styles.label2} title="Mandatory Field">
                Enter Email<span className={styles.astspan}>*</span>
              </label>
              <button
                onClick={handleAddEmailInput}
                className={styles.addemlbttn}
                style={{
                  marginLeft: "20px",
                  // margin: "15px",
                  backgroundColor: "#171C8F",
                  borderColor: "white",
                  borderRadius: "0.5rem",
                  color: "white",
                  // border: "black",
                  justifyContent: "flex-end",
                }}
                disabled={lastEmailInputValue.trim() === ""}
              >
                + Add More Emails
              </button>
            </div>
            <div className={styles.emailGrid}>
              {emailInputs.map((_, index) => (
                <div key={index} className={styles.emailGriditem}>
                  <input
                    name="emails"
                    placeholder="Enter Email"
                    style={{ width: "250px" }}
                    value={data.emails[index] || ""}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => handleRemoveEmailInput(index)}
                  >
                    &#10006;
                  </span>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "5px", fontStyle: "italic" }}>
              <p>Note: Only 10 email id's can be added at a time</p>
            </div>
          </div>
          <div className={styles.scndflex}>
            <label className={styles.label2} title="Mandatory Field">
              Select Email Alerts for: <span className={styles.astspan}>*</span>{" "}
            </label>
            <div className={styles.input_dash_wrapper}>
              {dashboardOptions.map((option) => (
                <div
                  key={option.dashboard_name}
                  style={{ marginRight: "10px" }}
                >
                  <input
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    type="checkbox"
                    value={option.dashboard_name}
                    checked={selected.includes(option.dashboard_name)}
                    onChange={handleSelect}
                  />
                  {option.display_name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.iServeflex} style={{ marginTop: "20px" }}>
          <div className={styles.iServe}>
            <label className={styles.label2}>Enter iServe Number</label>
            <input
              style={{
                width: "200px",
                borderRadius: "5px",
                border: "1px solid black",
              }}
              name="srnumber"
              value={data.srnumber}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div className={styles.subbttn}>
          <div
            style={{
              fontStyle: "italic",
              color: "gray",
              width: "50%",
              fontWeight: "400",
            }}
          >
            <p style={{ wordWrap: "break-word", marginLeft: "5px" }}>
              <input
                type="checkbox"
                id="disclaimerCheckbox"
                checked={disclaimerChecked}
                onChange={handleCheckboxChange}
                style={{ marginRight: "10px" }}
              />
              By checking this discalimer, I hereby declare that what is stated
              above is correct. I approve for daily alerts to be received by the
              added email id's. I also hereby confirm that I shall submit
              necessary proof of approval of Service request raised internally.
              Any discrepency arising out of a wrong declaration will be the
              responsibility of the individual concerned.
            </p>
            {/* <div className={styles.checkboxDisclaimer}>
              <input
                type="checkbox"
                id="disclaimerCheckbox"
                checked={disclaimerChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="disclaimerCheckbox" style={{ marginLeft: "5px" }}>
                I agree to the disclaimer
              </label>
            </div> */}
          </div>
          <div style={{ width: "50%" }} className={styles.finalbttn}>
            <button
              onClick={handleSubmit}
              className="btn btn-primary"
              style={{ backgroundColor: "#171C8F" }}
              // disabled={!disclaimerChecked}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
