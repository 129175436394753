import React from "react";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import vector from "../images/Vector.svg";
import vector2 from "../images/Vector2.svg";
import UserTable from "./UserTable";
import VendorTable from "./VendorTable";

const pages = [
  { svg1: vector, value: "Internal MSIL Users" },
  { svg1: vector2, value: "Vendors" }
];

export default function VendorSubNav() {
  const { name } = useParams();
  console.log(name);

  const [currentItem, setCurrentItem] = useState("Internal MSIL Users");

  const menuitem = (e) => {
    setCurrentItem(e.currentTarget.value);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <div>
        {/* <Button
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
          style={{ visibility: "hidden" }} // Hide the button
        >
          
        </Button> */}
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          {pages.map((page) => (
            <MenuItem key={page.value} value={page.value} onClick={menuitem}>
              {page.value}
            </MenuItem>
          ))}
        </Menu>

        {pages.map((page) => (
          <Button
            key={page.value}
            onClick={menuitem}
            value={page.value}
            name={page.value}
            sx={{ my: 1.8, color: "black" }}
            style={{
              borderBottom:
                page.value === currentItem ? "2px solid blue" : "none", marginLeft: "20px",
                fontFamily: "Roboto",
                fontWeight: "normal"
            }}
          >
            {page.value}
          </Button>
        ))}
        
        <div style={{ backgroundColor: "white" }}>
          {currentItem === "Internal MSIL Users" && <UserTable />}
          {currentItem === "Vendors" && <VendorTable />}
        </div>
      </div>
    </>
  );
}
