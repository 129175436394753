import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { Pagination } from "@mui/material";
import styles from "../sample/Tbl.module.css";
import { Search } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confirmation from "./Confirmation";
import { GET_ALL_DETAILS_URL, GET_DASHBOARD_URL, UPDATE_RECORDS_URL } from "../apiExports";

const UserTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All Users");
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [enrolledToFilter, setEnrolledToFilter] = useState("All Dashboards");
  const entriesPerPage = 6;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(GET_ALL_DETAILS_URL);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch data. Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_DASHBOARD_URL);
        setDashboardOptions(response.data || []);
      } catch (error) {
        console.error("Error fetching dashboard options:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatEnrolledTo = (enrolledTo) => {
    const words = enrolledTo.split("_");
    const formattedWords = words.map((word) => {
      if (word === "vi") {
        return "VI";
      } else if (word === "ok") {
        return "OK";
      } else if (word === "ng") {
        return "NG";
      } else if (word === "cum") {
        return "Cumulative";
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    });
    return formattedWords.join(" ");
  };

  const handleToggle = async (userId, email, currentStatus) => {
    const newStatus = currentStatus === "Active" ? "Inactive" : "Active";

    toast.info(
      <Confirmation
        message={`Are you sure you want to change the Status for: ${email}?`}
        onConfirm={async () => {
          const payload = {
            user_id: userId,
            newStatus: newStatus,
          };

          try {
            await axios.put(UPDATE_RECORDS_URL, payload);

            toast.success(`Status for: ${email} updated!`);
            fetchData();
          } catch (err) {
            console.log(err);
          }
        }}
        onCancel={() => toast.dismiss()}
      />,
      {
        autoClose: false,
      }
    );
  };

  const filteredData = data.filter(
    (item) =>
      item.email_id.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (statusFilter === "All Users" || item.status === statusFilter) &&
      (enrolledToFilter === "All Dashboards" ||
        formatEnrolledTo(item.enrolled_to) === enrolledToFilter)
  );

  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);
  const len = filteredData.length;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset page number when searching
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(1); // Reset page number when changing filter
  };

  const handleEnrolledToFilterChange = (event) => {
    setEnrolledToFilter(event.target.value);
    setCurrentPage(1); // Reset page number when changing filter
  };

  return (
    <>
      <div
        style={{
          color: "black",
          marginBottom: "20px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div className={styles.filtersearchsort}>
          <h3
            className={styles.heading}
            style={{
              fontFamily: "roboto",
              color: "Black",
              fontWeight: "normal",
            }}
          >
            Internal MSIL Users
          </h3>
          <div className={styles.componenetssrch}>
            <div className={styles.filters}>
              <label>
                Enrolled To
                <Select
                  value={enrolledToFilter}
                  onChange={handleEnrolledToFilterChange}
                  displayEmpty
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    color: "grey",
                    width: "220px",
                    alignSelf: "center",
                    borderRadius: "5px",
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid",
                      borderColor: "black",
                    },
                  }}
                >
                  <MenuItem value="All Dashboards">All Dashboards</MenuItem>
                  {dashboardOptions.map((option) => (
                    <MenuItem
                      key={option.dashboard_name}
                      value={option.display_name}
                    >
                      {option.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </label>
            </div>
            <div>
              <label>
                Status
                <Select
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  displayEmpty
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    color: "grey",
                    borderRadius: "5px",
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid",
                      borderColor: "black",
                    },
                  }}
                >
                  <MenuItem value="All Users">All Users</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </label>
            </div>

            <div>
              <TextField
                label="Search by Email"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{
                  // marginRight: "20px",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid",
                    borderColor: "black",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <Table className={styles.tblstyle}>
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell style={{ textAlign: "left", width: "25%" }}>
                Email
              </TableCell>
              <TableCell style={{ textAlign: "left", width: "15%" }}>
                Created On
              </TableCell>
              <TableCell style={{ textAlign: "left", width: "20%" }}>
                Enrolled To
              </TableCell>
              <TableCell style={{ textAlign: "left", width: "20%" }}>
                Approved By
              </TableCell>
              <TableCell style={{ textAlign: "left", width: "20%" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((item) => (
              <TableRow key={item.user_id} className={styles.tableRow}>
                <TableCell style={{ textAlign: "left" }}>
                  {item.email_id}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {formatDate(item.created_on)}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {formatEnrolledTo(item.enrolled_to)}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  {item.approved_by}
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>
                  <Switch
                    checked={item.status === "Active"}
                    onChange={() =>
                      handleToggle(item.user_id, item.email_id, item.status)
                    }
                    color="primary"
                  />
                  {item.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(len / entriesPerPage)}
            page={currentPage}
            color="primary"
            onChange={handlePageChange}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserTable;
