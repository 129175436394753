import "./App.css";
import Nav from "./components/Nav2";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import UserTable from "./components/UserTable";
import LogTable from "./components/LogTable";
import SubNav from "./components/SubNav";
import CCRegistration from "./components/CCRegistration";
import VendorSubNav from "./components/VendorSubNav";
import Registration from "./sample/Registration";
import VendorTable from "./components/VendorTable";
import Assignment from "./AdminControls/Assignment";
import FirstPage from "./exp/FirstPage";
import ToDoList from "./exp/samplecode";
import NewRegistration from "./sample/NewRegistration";
import ContextReg from "./sample/ContextReg";
import EmailRect from "./sample/EmailRect";

function App() {
  const [authStatus, setAuthStatus] = useState({
    name1: "",
    status: false,
  });

  const name2 = authStatus.name1;
  console.log("name2 = = ", name2);

  return (
    <>
      {" "}
      <div className="App">
        <BrowserRouter>
          <Routes>
            {/* <Route
              path="/"
              element={<Form setAuthStatusParent={setAuthStatus} />}
            /> */}
            <Route path="/" element={<Nav />} />
            <Route path="/usertable" element={< UserTable />} />
            <Route path="/vendortable" element={< VendorTable />} />
            <Route path="/logtable" element={< LogTable />} />
            <Route path="/subnav" element={< SubNav />} />
            <Route path="/vtab" element={< VendorSubNav />} />
            <Route path="/reg" element={< Registration />} />
            <Route path="/ccr" element={< CCRegistration />} />
            <Route path="/admin" element={< Assignment />} />
            <Route path="/exp" element={< FirstPage />} />
            <Route path="/exp2" element={< EmailRect />} />



          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
