import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./FirstPage.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SIGNUP_URL } from "../apiExports";

export default function FirstPage() {
  const [data, setData] = useState({
    emails: [],
    srnumber: "",
    enrolled_to: [],
    approved_by: "",
  });

  const [emailInputs, setEmailInputs] = useState([0]);

  useEffect(() => {
    console.log("Yhi hai");
  }, []);

  const options = [
    { label: "Critical Parameters", value: "critical_parameters" },
    { label: "VI Machine Brake", value: "vi_machine_brake" },
    { label: "Engine Parameters", value: "engine_parameters" },
  ];

  const [selected, setSelected] = useState([]);
  const [lastEmailInputValue, setLastEmailInputValue] = useState("");

  const handleRemoveEmailInput = (indexToRemove) => {
    setEmailInputs((prevInputs) =>
      prevInputs.filter((_, index) => index !== indexToRemove)
    );

    console.log("Removal");
    setData((prevData) => {
      const newEmails = prevData.emails.filter((_, index) => index !== indexToRemove);
      return {
        ...prevData,
        emails: newEmails
      };
    });
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelected((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "emails") {
      const emailArray = [...data.emails];
      emailArray[index] = value.trim();
      setData((prev) => ({
        ...prev,
        [name]: emailArray,
      }));

      // Enable the "Add More Emails" button if the last email input has a value
      if (index === emailInputs.length - 1) {
        setLastEmailInputValue(value.trim());
      }
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAddEmailInput = () => {
    if (emailInputs.length < 10 && lastEmailInputValue.trim() !== "") {
      setEmailInputs((prev) => [...prev, emailInputs.length]);
      setLastEmailInputValue(""); // Reset last email input value
    }
  };

  const isValidEmailArray = () => {
    return data.emails.every((email) => isValidEmail(email));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@maruti\.co\.in$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if ( selected.length === 0) {
      alert("Selecting at least 1 Dashboard is Mandatory Fields.");
      return;
    }

    if (data.emails.every((email) => !email.trim())) {
      alert("Enter at least 1 email!");
      return;
    }

    if (!isValidEmailArray()) {
      alert(
        "Invalid email format. Please use emails ending with @maruti.co.in"
      );
      return;
    }

    const payloadArray = [];

    emailInputs.forEach((index) => {
      selected.forEach((selectedValue) => {
        payloadArray.push({
          emails: [data.emails[index]],
          srnumber: data.srnumber,
          enrolled_to: selectedValue,
          approved_by: data.approved_by,
          status: "Active",
        });
      });
    });

    const promises = payloadArray.map((payload) => {
      return axios({
        method: "POST",
        url: SIGNUP_URL,
        data: payload,
      });
    });

    Promise.all(promises)
      .then((responses) => {
        console.log("done");
        alert("Registration Successful!");
        window.location.reload();
        toast.success("Done");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="regcontainer" style={{ marginBottom: "20px", marginLeft: "20px", marginRight: "20px" }}>
        <h3> Internal MSIL User Registration </h3>
        <div className={styles.emaildashb}>
          <div className={styles.emailflex}>
            <label className={styles.label2} title="Mandatory Field">
              Enter Email<span className={styles.astspan}>*</span>
            </label>
            <button
              onClick={handleAddEmailInput}
              className={styles.addemlbttn}
              style={{
                marginLeft: "20px",
                margin: "15px",
                backgroundColor: "#D3D3D3",
                borderColor: "black",
                borderRadius: "10px",
                // border: "black",
                justifyContent: "flex-end",
              }}
              disabled={lastEmailInputValue.trim() === ""} // Disable button if last email input is empty
            >
              + Add More Emails
            </button>
            <div className={styles.emailGrid}>
              {emailInputs.map((index) => (
                <div key={index} className={styles.emailGriditem}>
                  <input
                    name="emails"
                    style={{ width: "250px" }}
                    value={data.emails[index] || ""}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => handleRemoveEmailInput(index)}
                  >
                    &#10006;
                  </span>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "5px", fontStyle: "italic" }}>
              <p>Note: Only 10 email id's can be added at a time</p>
            </div>
          </div>
          <div className={styles.scndflex}>
            <label className={styles.label2} title="Mandatory Field">
              Selected Dashboard <span className={styles.astspan}>*</span>{" "}
            </label>
            <div className={styles.input_dash_wrapper}>
              {options.map((option) => (
                <div key={option.value}>
                  <input
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    type="checkbox"
                    value={option.value}
                    checked={selected.includes(option.value)}
                    onChange={handleSelect}
                  />
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.srnoappname} style={{ marginTop: "20px" }}>
          <div className={styles.inputflex}>
            <label className={styles.label2}>
              Enter SR Number
            </label>
            <input
              style={{ width: "200px", borderRadius: "5px" }}
              name="srnumber"
              value={data.srnumber}
              onChange={(e) => handleChange(e)}
            />
          </div>
          {/* <div className={styles.inputflex} style={{ marginRight: "90px" }}>
            <label title="Mandatory Field" className={styles.label2}>
              Enter Approver Name <span className={styles.astspan}>*</span>{" "}
            </label>
            <input
              style={{ width: "400px", borderRadius: "5px" }}
              name="approved_by"
              value={data.approved_by}
              onChange={(e) => handleChange(e)}
            />
          </div> */}
        </div>
        <div className={styles.subbttn}>
          <div style={{ fontStyle: "italic", color: "gray" }}>
            <p>* Disclaimer: The QA/QD team will be responsible in User Management for triggering alerts via the alert management system.</p>
          </div>
          <div><button
            onClick={handleSubmit}
            className="btn btn-primary"
            style={{ backgroundColor: "#171C8F" }}
          >
            Submit
          </button></div>
        </div>
      </div>
    </>
  );
}
