import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "./Confirmation.module.css";

const Confirmation = ({ message, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    onConfirm();
    toast.dismiss();
  };

  const handleCancel = () => {
    onCancel();
    toast.dismiss();
  };

  return (
    <div>
      <div>{message}</div>
      <div className={styles.buttondisplay}>
        <button type="button" className={`btn btn-primary btn-sm ${styles.actionbutton}`} style={{backgroundColor: "#171C8F"}}  onClick={handleConfirm}>Confirm</button>
        <button type="button" className={`btn btn-primary btn-sm ${styles.actionbutton}`} style={{backgroundColor: "#171C8F"}} onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default Confirmation;
