import React from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Header from "./Header";
import vector from "../images/Vector.svg";
import vector2 from "../images/Vector2.svg";
import group1 from "../images/Group 2683.svg";
import group3 from "../images/group3.svg";
import { useParams } from "react-router-dom";
import LogTable from "./LogTable";
import SubNav from "./SubNav";
import VendorSubNav from "./VendorSubNav";
import sub from '../images/Subtract.svg';
import Assignment from "../AdminControls/Assignment";

const pages = [
  { svg1: vector, value: "Email Registration" },
  { svg1: vector2, value: "List of added users" },
  { svg1: sub, value: "Email Logs" },
  { svg1: group3, value: "Access Management" }
];

export default function Nav() {
  const { name } = useParams();
  console.log(name);

  const [currentItem, setCurrentItem] = useState("Email Registration");

  const menuitem = (value) => {
    setCurrentItem(value);
  };
  const [formData, setFormData] = useState([]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      <div>
        <Header name={name} />
        <div style={{ width: "100%" }}>

          <div className="Adiv2">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.value}
                    value={page.value}
                    name={page.value}
                  >
                    <Typography textAlign="center" sx={{ color: "black" }}>
                      <img src={page.svg1} /> {page.value}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                backgroundColor: "#F0F4FF",
                alignItems: "",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.value}
                  onClick={() => menuitem(page.value)}
                  // value={page.value}
                  // name={page.value}
                  sx={{ my: 1.8, color: currentItem === page.value ? "#171C8F" : "black", 
                  // display: "block" 
                }}
                  style={{
                    borderLeft: "",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontWeight: "normal",
                    fontSize: "14px",
                    lineHeight: "26.5px",
                    letterSpacing: "-0.35px",
                    fontFamily: "Roboto",
                    // color: "#171C8F",
                    borderBottom:
                      page.value === currentItem ? "5px solid blue" : "",
                  }}
                >
                  <img src={page.svg1} style={{ fill: "#012386", paddingRight: "0.2rem" }} />{" "}
                  {page.value}
                </Button>
              ))}
            </Box>
            <div style={{backgroundColor: 'white'}}>
              {currentItem == "Email Registration" && <SubNav />}

              {currentItem == "List of added users" && ( <VendorSubNav />)}

              {currentItem == "Email Logs" && ( <LogTable />)}

              {currentItem == "Access Management" && ( <Assignment />)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
