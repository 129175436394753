import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import styles from "./EmailLogs.module.css";
import { EMAIL_LOGS_URL, GET_DASHBOARD_URL } from "../apiExports";

const LogTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const entriesperPage = 6;
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchReceiverTerm, setSearchReceiverTerm] = useState("");
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [dashboardFilter, setDashboardFilter] = useState("All");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(EMAIL_LOGS_URL);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch data. Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_DASHBOARD_URL);
        setDashboardOptions(response.data || []);
      } catch (error) {
        console.error("Error fetching dashboard options:", error);
      }
    };

    fetchData();
  }, []);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDashboardName = (name) => {
    const words = name.split("_");
    const formattedWords = words.map((word) => {
      if (word === "vi") {
        return "VI";
      } else if (word === "ok") {
        return "OK";
      } else if (word === "ng") {
        return "NG";
      } else if (word === "cum") {
        return "Cumulative";
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    });
    return formattedWords.join(" ");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset pagination to first page when search term changes
  };

  const handleReceiverSearch = (event) => {
    setSearchReceiverTerm(event.target.value);
    setCurrentPage(1); // Reset pagination to first page when search term changes
  };

  const handleDashboardFilter = (event) => {
    setDashboardFilter(event.target.value);
    setCurrentPage(1); // Reset pagination to first page when filter changes
  };

  const filteredData = data.filter(
    (item) =>
      item.sent_from.toLowerCase().includes(searchTerm.toLowerCase()) &&
      item.sent_to.toLowerCase().includes(searchReceiverTerm.toLowerCase()) &&
      (dashboardFilter === "All" || item.dashboard_name === dashboardFilter)
  );

  const startIndex = (currentPage - 1) * entriesperPage;
  const endIndex = startIndex + entriesperPage;
  const currentData = filteredData.slice(startIndex, endIndex);
  const len = filteredData.length;

  return (
    <div style={{
      color: "black",
      marginBottom: "20px",
      marginLeft: "20px",
      marginRight: "20px",
    }}>
      <div className={styles.filtersearchsort}>
        <h3 className={styles.heading} style={{fontFamily: "roboto", color: "Black", fontWeight: 'normal', marginTop: "10px"}}>Email Logs</h3>
        <div className={styles.componenetssrch}>
          <div>
            <label>
              Dashboard
              <Select
                value={dashboardFilter}
                onChange={handleDashboardFilter}
                displayEmpty
                inputProps={{ "aria-label": "Select dashboard" }}
                style={{ marginLeft: "5px", marginRight: "5px", color: "grey", width: "220px", borderRadius: "5px" }}
                sx={
                  {
                '& .MuiOutlinedInput-notchedOutline':{
                  border:"1px solid", borderColor: "black"
                }
                  }
                }
              >
                <MenuItem value="All">All Dashboards</MenuItem>
                {dashboardOptions.map((option) => (
                    <MenuItem
                      key={option.dashboard_name}
                      value={option.dashboard_name}
                    >
                      {option.display_name}
                    </MenuItem>
                  ))}
              </Select>
            </label>
          </div>
          <div>
            <TextField
              // label="Search by Sent From"
              label="Search by Sent From"
              value={searchTerm}
              variant="outlined"
              onChange={handleSearch}
              style={{ marginRight: "20px", marginLeft: "20px", borderRadius: "5px"}}
              sx={
                {
              '& .MuiOutlinedInput-notchedOutline':{
                border:"1px solid",
              }
                }
              }
            />
          </div>
          <div>
            <TextField
              label="Search by Sent To"
              value={searchReceiverTerm}
              onChange={handleReceiverSearch}
              style={{ borderRadius: "5px" }}
              sx={
                {
              '& .MuiOutlinedInput-notchedOutline':{
                border:"1px solid",
              }
                }
              }
            />
          </div>
        </div>
      </div>
      <Table class={styles.tblstyle}>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: "left", width: "25%", paddingTop: "15px", paddingBottom: "15px" }}>Dashboard</TableCell>
            <TableCell style={{ textAlign: "left", width: "15%", paddingTop: "15px", paddingBottom: "15px" }}>Sent Date</TableCell>
            <TableCell style={{ textAlign: "left", width: "30%", paddingTop: "15px", paddingBottom: "15px" }}>Sent From</TableCell>
            <TableCell style={{ textAlign: "left", width: "30%", paddingTop: "15px", paddingBottom: "15px" }}>Sent To</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData.map((item) => (
            <TableRow key={item?.user_id}>
              <TableCell style={{ textAlign: "left", paddingTop: "25px", paddingBottom: "25px" }}>{formatDashboardName(item?.dashboard_name)}</TableCell>
              <TableCell style={{ textAlign: "left", paddingTop: "25px", paddingBottom: "25px" }}>{formatDate(item?.sent_time)}</TableCell>
              <TableCell style={{ textAlign: "left", paddingTop: "25px", paddingBottom: "25px" }}>{item?.sent_from}</TableCell>
              <TableCell style={{ textAlign: "left", paddingTop: "25px", paddingBottom: "25px" }}>{item?.sent_to}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={styles.pagination}>
        <Pagination
          count={Math.ceil(len / entriesperPage) || 1}
          color="primary"
          onChange={(event, value) => {
            handlePageChange(value);
          }}
        />
      </div>
    </div>
  );
};

export default LogTable;
