import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./Assignment.module.css";
import { GET_DASHBOARD_URL } from "../apiExports";
export default function Assignment() {
  const [email, setEmail] = useState("");
  const [emailInputs, setEmailInputs] = useState([0]);
  const [selectedRole, setSelectedRole] = useState("");
  const [dashboardPrivileges, setDashboardPrivileges] = useState({});
  const [dashboards, setDashboards] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    axios
      .get(GET_DASHBOARD_URL)
      .then((response) => {
        setDashboards(response.data);
      })
      .catch((error) => {
        console.error("Error fetching dashboards:", error);
      });
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleDashboardPrivilegeChange = (event) => {
    const { name, checked } = event.target;
    setDashboardPrivileges((prevPrivileges) => ({
      ...prevPrivileges,
      [name]: checked,
    }));
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelected((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    // assignment of privileges
    console.log(
      `Assigning role ${selectedRole} and privileges to user with email ${email}`
    );
    console.log("Dashboard Privileges:", dashboardPrivileges);
    // Reset the page after submission
    setEmail("");
    setSelectedRole("");
    setDashboardPrivileges({});
  };

  return (
    <div style={{
        marginBottom: "20px",
        marginLeft: "20px",
        marginRight: "20px",
        // marginTop: "60px"
      }}>
      <span style={{marginBottom: '100rem', color: "white"}}>tdfchgvjb</span> 
      <h3 style={{fontFamily: "roboto", color: "Black", fontWeight: 'normal', marginLeft: "5px"}}>Access Management</h3>
        <div className={styles.emaildashb}>
          <div className={styles.emailflex}>
            <label className={styles.label2} style={{marginTop: '10px'}}>Enter Email</label>
            {/* <button
                onClick={handleAddEmailInput}
              className={styles.addemlbttn}
              style={{
                marginLeft: "20px",
                margin: "15px",
                backgroundColor: "#D3D3D3",
                borderColor: "black",
                borderRadius: "10px",
                border: "black",
                justifyContent: "flex-end",
              }}
                disabled={lastEmailInputValue.trim() === ""}
            >
              + Add More Emails
            </button> */}
            <div className={styles.emailGrid}>
              {/* {emailInputs.map((index) => (
                <div key={index} className={styles.emailGriditem}>
                  <input
                    name="emails"
                    placeholder="Enter Email"
                    style={{ width: "250px" }}
                    value={data.emails[index] || ""}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => handleRemoveEmailInput(index)}
                  >
                    &#10006;
                  </span>
                </div>
              ))} */}
              <input
              type="email"
              id="email"
              placeholder="Enter Email"
              value={email}
              onChange={handleEmailChange}
              style={{width: "20rem", height: "2.5rem", borderRadius: "5px", border: "1px solid black" }}
              required
            />

            </div>
          </div>
          <div className={styles.scndflex}>
            <label className={styles.label2}>Select Email Alerts for:</label>
            <div >
              {dashboards.map((dashboard) => (
                <label key={dashboard.dashboard_name} style={{marginRight: '10px'}}>
                  <input
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    type="checkbox"
                    value={dashboard.dashboard_name}
                    checked={selected.includes(dashboard.dashboard_name)
                    }
                    onChange={handleSelect}
                  />

                  {dashboard.display_name}
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.srnoappname}>
          <div className={styles.inputflex} style={{fontFamily: "roboto", fontWeight: "400"}}>
            <label className={styles.label2}>Select Role</label>
            <select
              id="role"
              value={selectedRole}
              onChange={handleRoleChange}
              style={{ width: "15rem", height: "2.5rem", borderRadius: "5px", backgroundColor: "white",  fontWeight: "normal" }}
              required
            >
              <option style={{ fontWeight: "300", fontSize: "1rem"}} value="">Select Role</option>
              <option style={{  fontWeight: "300", fontSize: "1rem"}} value="Admin">Admin</option>
              <option style={{ fontWeight: "300", fontSize: "1rem"}} value="User">User</option>
            </select>
          </div>
        </div>

        <div className={styles.subbttn}>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: "#171C8F" }}
            type="submit"
            onClick={handleSubmit}
          >
            Assign Role and Privileges
          </button>
        </div>
    </div>
  );
}
